/* marquee.css */
.marquee-container {
  overflow: hidden;
  white-space: nowrap;
  position: relative;
}

.marquee-content {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 30s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(100%); /* Starts from right side */
  }
  100% {
    transform: translateX(-100%); /* Ends off-screen to the left side */
  }
}
