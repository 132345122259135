/* To change the position of icon from left to right */

.react-datepicker__input-container .react-datepicker__calendar-icon {
  right: 0 !important;
}

.react-datepicker__input-container input {
  border-bottom: 1px solid black;
  /* width: 400px; */
}
