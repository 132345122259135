.not-found-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-color: #b4b1b1;
}

.not-found-content {
  max-width: 600px;
}

.not-found-title {
  font-weight: 700;
  color: #027970;
}
